.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: rgb(51, 51, 51);
  padding: 20px 10%;
  color: white;
  margin-top: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footer-column {
  flex: 1;
  text-align: center;
}

.footer-logo {
  max-width: 15em; /* Adjust as needed */
  height: auto;
}

.footer-info {
  text-align: center;
  font-size: 14px;
  border-top: 1px solid rgba(29, 26, 26, 0.2);
  padding-top: 10px;
}


/* Change the placeholder color to gray */
#referral-code-label {
  color: rgb(181, 181, 181);
}

/* Change the input color */
#referral-code {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}

/* Change the input underline color when focused (optional) */
#referral-code-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(181, 181, 181); /* Change 'green' to your desired focused input border color */
}


/* Change the placeholder color to gray */
#pastor-status-label {
  color: rgb(181, 181, 181); 
}

/* Change the input color */
#pastor-status {
  color: rgb(181, 181, 181);  /* Change 'blue' to your desired input text color */
}

/* Change the input underline color when focused (optional) */
#pastor-status-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(181, 181, 181);  /* Change 'green' to your desired focused input border color */
}



#nationality-label.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#nationality.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}

/* Change the input underline color when focused (optional) */
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(181, 181, 181); /* Change 'green' to your desired focused input border color */
}

/* For datepicker */
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}

.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  min-width: 100%;
}

#country-of-residence-label.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#country-of-residence.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}


#country-of-residence-label.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#country-of-residence.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}





#first-language-level.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#first-language-level.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}

#second-language-level.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#second-language-level.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}



#children-info.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#children-info.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}






#marriage-status.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#marriage-status.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}


#gender.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change 'gray' to your desired placeholder color */
}

#gender.MuiInputBase-input {
  color: rgb(181, 181, 181); /* Change 'blue' to your desired input text color */
}

/* Text color for typed text and selection */
#gender.MuiInputBase-input,
#gender.MuiInputBase-input::placeholder,
#gender.MuiInputBase-input:focus {
  color: rgb(34, 34, 34); /* Grey for typed text and selection */
}

/* Light gray color for the initial placeholder option */
#gender.MuiInputBase-input::placeholder {
  color: rgb(200, 200, 200); /* Light gray color */
}

/* Gray color for the selected option */
#gender.MuiInputBase-input:not(:placeholder-shown) {
  color: rgb(181, 181, 181); /* Gray color */
}



/* Change the color of the Date of Birth (DOB) placeholder */
#dateOfBirth.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change to your desired placeholder color */
}

/* Change the color of the Date of Birth (DOB) input text */
#dateOfBirth.MuiInputBase-input {
  color: rgb(0, 0, 0); /* Change to your desired input text color */
}


.custom-checkbox {
  display: flex; /* Align checkbox and label horizontally */
  align-items: center; /* Center vertically */
  font-size: 18px; /* Adjust the font size to make the label bigger */
}

.custom-checkbox-input {
  width: 24px; /* Adjust the width and height to make the checkbox bigger */
  height: 24px;
  margin-right: 8px; /* Add spacing between checkbox and label */
}

.custom-checkbox-label {
  flex: 1; /* Allow the label to grow and take remaining space */
}

/* Change the color of the Date of Birth (DOB) placeholder */
#dateOfBirth.MuiFormLabel-root {
  color: rgb(181, 181, 181); /* Change to your desired placeholder color */
}

/* Change the color of the Date of Birth (DOB) input text */
#dateOfBirth.MuiInputBase-input {
  color: rgb(0, 0, 0); /* Change to your desired input text color */
}

.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  min-width: 100%;
}